<template>
  <div id="app">
    <VHeader v-if="name !== 'login' && name !== 'register' && name !== 'surfaceDetail'"/>
    <router-view/>
    <VFooter v-if="name !== 'login' && name !== 'register' && name !== 'surfaceDetail'"/>
  </div>
</template>
<script>
import VHeader from '@/components/v-header'
import VFooter from '@/components/v-footer'
export default {
  name: 'app',
  components: {
    VHeader,
    VFooter
  },
  computed: {
    name () {
      return this.$route.name
    } 
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  methods: {
    $_isMobile() {
      const rect = document.body.getBoundingClientRect()
      return rect.width - 1 < 768
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        this.$store.commit('setDevice',isMobile)
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.swiper-slide img{
	width: 100%;
	height: 100%;
}
.banber_swiper {
    display: block;
    width: 100%;
    height: 578px;
    position: relative;
	overflow: hidden;
}
</style>
