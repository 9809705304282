import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    device: document.body.getBoundingClientRect().width - 1 < 768 ? true : false,
  },
  mutations: {
    setDevice (state, pyload) {
      state.device = pyload
    }
  },
  actions: {
  },
  modules: {
  }
})
