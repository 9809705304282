<template>
  <div class="footer_container">
    <div class="footer_inner m_1200">
      <ul class="footer_list">
        <li class="item">
          <div class="title">心理服务</div>
          <a href="http://cp.sgdj.org.cn/" target="_blank" class="link" style="color:#fff">心理评测</a>
          <router-link to="/consultingService?type=1" tag="div" class="link">心理咨询</router-link>
          <div class="link" @click="qcode">心理问答 </div>
        </li>
        <li class="item">
          <div class="title">心理咨询</div>
          <router-link to="/consultingService?type=1&val=婚姻关系" tag="div" class="link">婚姻关系</router-link>
          <router-link to="/consultingService?type=1&val=学习压力" tag="div" class="link">学习压力</router-link>
          <router-link to="/consultingService?type=1&val=亲子教育" tag="div" class="link">亲子教育</router-link>
          <router-link to="/consultingService?type=1&val=争吵分析" tag="div" class="link">争吵分析</router-link>
          <router-link to="/consultingService?type=1&val=摆脱失眠" tag="div" class="link">摆脱失眠</router-link>
        </li>
        <li class="item">
          <div class="title">联系我们</div>
          <p class="text">服务热线：{{config.service_hotline}}</p>
          <p class="text">{{config.work_time}}</p>
          <p class="text">服务邮箱：{{config.email}}</p>
          <p class="text">服务地址：{{config.address}} </p>
        </li>
        <li class="item">
          <div class="title">北京市云家心理社会工作事务所</div>
          <p class="content">
            {{config.introduce}}
          </p>
        </li>
      </ul>
      <div class="desc">北京市云家心理社会工作事务所</div>
    </div>
    <div class="footer_desc">版权所有 Copyright @ 2017-2018 All right service. &nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #f8f8f8;">备案号：{{config.web_site_icp}}</a></div> 
	  <el-dialog title="心理咨询" :visible.sync="isCode" width="400px">
		  <img src="../assets/images/code.jpg" alt="" style="width: 100%;">
		<span slot="footer" class="dialog-footer">
		  <el-button type="primary" @click="isCode = false">关 闭</el-button>
		</span>
	  </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'VHfooter',
  data () {
    return {
      config: '',
	  isCode:false
    }
  },
  created () {
	this.$http({
	  method: 'POST',
	  url: '/other/getConfig',
	  data: {}
	}).then((res)=>{
		this.config=res.data
	})
  },
  methods:{
	  qcode(){
	  	this.isCode=true
	  },
  }
}
</script>

<style lang="scss">
.footer_container {
  background-color: #424343;
  padding-top: 36px;
  margin-top: auto;
  .footer_inner {
    padding-bottom: 36px;
    .footer_list {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      .item {
        .title {
          font-size: 15px;
          margin-bottom: 28px;
          white-space: nowrap;
        }
        &:nth-child(1),
        &:nth-child(2) {
          .link {
            font-size: 12px;
            cursor: pointer;
            &+.link {
              margin-top: 20px;
            }
          }
        }
        &:nth-child(3) {
          .text + .text {
            margin-top: 20px;
          }
        }
        &:nth-child(4) {
          flex: 0 0 288px;
          .content {
            line-height: 2;
            text-align: justify;
            margin-top: -5px;
          }
        }
      }
    }
    .desc {
      margin-top: 50px;
      color: #ffffff;
      font-size: 12px;
    }
  }
  .footer_desc {
    height: 70px;
    color: #fff;
    font-size: 14px;
    line-height: 70px;
    text-align: center;
    background-color: #000000;
  }
}
@media screen and (max-width: 768px) {
  .footer_container {
    background-color: #424343;
    padding-top: 36px;
    margin-top: auto;
    .footer_inner {
      padding-bottom: 36px;
      .footer_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 15px;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        .item {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &+.item {
            margin-top: 20px;
          }
          .title {
            width: 100%;
            font-size: 15px;
            margin-bottom: 12px;
            white-space: nowrap;
            font-weight: bold;
          }
          &:nth-child(1),
          &:nth-child(2) {
            .link {
              font-size: 12px;
              cursor: pointer;
              margin-right: 12px;
              margin-bottom: 12px;
              &+.link {
                margin-top: 0px;
                
              }
            }
          }
          &:nth-child(3) {
            .text {
              width: 100%;
            }
            .text + .text {
              margin-top: 12px;
            }
          }
          &:nth-child(4) {
            flex: 0 0 288px;
            .content {
              line-height: 2;
              text-align: justify;
              margin-top: -5px;
            }
          }
        }
      }
      .desc {
        margin: 24px 15px 0;
        color: #ffffff;
        font-size: 12px;
      }
    }
    .footer_desc {
      height: 42px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      background-color: #000000;
    }
  }
}
</style>