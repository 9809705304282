<template>
  <div class="header_container">
    <div class="header_fixed">
      <div class="nav_box m_1200">
        <img src="../assets/images/logo.png" alt="" class="logo" />
		<span class="logotxt">北京云家心理社会工作事务所</span>
        <ul class="nav_list">
          <router-link
            :to="item.url"
            tag="li"
            class="item"
			v-for="(item,index) in navList" :key="index"
            >{{item.name}}</router-link
          >  
        </ul>
        <div class="login_wrap">
          <router-link
            to="/my"
            tag="span"
            class="text logining"
            v-if="userinfo"
            >{{ userinfo.phone }}</router-link
          >
          <router-link to="/login" tag="span" class="text" v-else
            >登录/注册</router-link
          >
          <span @click="logout" class="logout" v-if="userinfo">退出登录</span>
        </div>
      </div>
      <div class="nav_mobile_box">
        <router-link to="/"><img src="../assets/images/mobile_logo.png" alt="" class="logo" /></router-link>
		<span class="logotxt">北京云家心理社会工作事务所</span>
        <img
          src="../assets/images/menu.png"
          alt=""
          class="menu"
          @click="isShow = !isShow"
        />
        <el-collapse-transition>
          <ul class="nav_list" v-show="isShow" @click="isShow = !isShow">
            <router-link
              :to="item.url"
              tag="li"
              class="item"
            			v-for="(item,index) in navList" :key="index"
              >{{item.name}}</router-link
            >  
          </ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VHdeader',
  data () {
    return {
      isShow: false,
      userinfo: '',
	  navList:[]
    }
  },
  computed: {
    name () {
      return this.$route.name
    }
  },
  created () {
    this.userinfo = localStorage.getItem('userinfo')
      ? JSON.parse(localStorage.getItem('userinfo'))
      : ''
	// 轮播图
	this.$http({
	  method: 'POST',
	  url: '/other/getPage',
	  data: {}
	}).then((res)=>{
		this.navList=res.page
	})
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$message.warning('退出登陆成功')
      this.$router.push({ name: 'home' })
    },
    userinfoHref () {
      this.$router.push({ name: 'my' })
    }
  },
  props: {
    fixed: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.header_container {
  height: 80px;
  background-color: #fff;
  .header_fixed {
    z-index: 10000;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 80px;
    background: #fff;
    z-index: 100;
    .nav_mobile_box {
      display: none;
    }
    .nav_box {
      display: flex;
      align-items: center;
      .logo {
        flex: 0 0 103px;
        height: 47px;
        margin-right: 10px;
      }
	  .logotxt{
		  font-size: 18px;
		  margin-right: 150px;
		  color: #333;
	  }
      .nav_list {
        display: flex;
        align-items: center;
        font-size: 16px;
        .item {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 400;
		  width: 85px;
          &.active {
            color: #e51418;
          }
          & + .item {
          }
        }
      }
      .login_wrap {
        margin-left: auto;
        color: #e51418;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
        position: relative;
        .logout {
          color: #666;
          font-size: 14px;
          margin-left: 10px;
        }
        .dropdown {
          display: none;
          background-color: #fff;
          position: absolute;
          top: 55px;
          right: 0;
          width: 100px;
          padding: 10px;
          opacity: 1;
          transition: 0.3s;
          transform: translateY(-10%);
          font-size: 14px;
          border: solid 1px #f8f8f8;
          .line1 {
            padding-top: 4px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e4e4e4;
            display: block;
            .line1-1 {
              font-size: 14px;
              color: #ee4e5b;
              padding-bottom: 8px;
            }
          }
          .line2 {
            font-size: 14px;
            color: #ee4e5b;
            padding-top: 8px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .header_container {
    height: 40px;
    background-color: #e51418;
    .header_fixed {
      z-index: 10000;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 40px;
      background: #e51418;
      z-index: 100;
      .nav_box {
        display: none;
      }
      .nav_list {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.9);
        .item {
          color: #fff;
          padding: 20px 15px;
          &.active {
            color: #e51418;
          }
        }
      }
      .nav_mobile_box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        .logo {
          width: 58px;
          height: 27px;
        }
		.logotxt{
			font-size: 16px;
			color: #fff;
		}
      }
    }
  }
}
</style>
