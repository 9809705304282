import router from '../router'
import axios from 'axios'
import Qs from 'qs'
import { Message, Loading } from 'element-ui'
import store from '../store'

let instance = axios.create({
	timeout: 20000,
	baseURL: '/api',
	transformRequest: [function (data) {
		if (data instanceof FormData) return data
		return Qs.stringify(data)
	}]
})
//loading对象
let loading;
//当前正在请求的数量
let needLoadingRequestCount = 0;

//显示loading
function showLoading({ loadingTarget = 'body', loadingText = '努力加载中...' }) {
	if (needLoadingRequestCount === 0 && !loading) {
		loading = Loading.service({
			lock: true,
			text: loadingText,
			background: 'rgba(255, 255, 255, 0.5)',
			target: loadingTarget
		});
	}
	needLoadingRequestCount++;
}

//隐藏loading
function hideLoading() {
	needLoadingRequestCount--;
	needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
	if (needLoadingRequestCount === 0) {
		loading.close();
		loading = null;
	}
}



// POST 传参序列化
instance.interceptors.request.use(config => {
	// if (config.headers.showLoading !== false) {
	// 	let loadingData = {}
	// 	config.headers.loadingTarget && (loadingData.loadingTarget = config.headers.loadingTarget)
	// 	config.headers.loadingText && (loadingData.loadingText = config.headers.loadingText)
	// 	showLoading(loadingData);
	// }
	let token = localStorage.getItem('token')
	token && (config.headers.token = token)
	return config
}, error => {
	//判断当前请求是否设置了不显示Loading
	// if (config.headers.showLoading !== false) {
	// 	hideLoading();
	// }
	return Promise.reject(error)
})
let timer = null
// 返回结果处理
instance.interceptors.response.use(response => {
	//判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
	// if (response.config.headers.showLoading !== false) {
	// 	setTimeout(() => {
	// 		hideLoading();
	// 	}, 200)
	// }

	const { data } = response
	if (response.config.responseType === "arraybuffer") {
		return data
	}
	switch (data.code) {
		case 200:
			return data
		case 402:
			Message.warning(data.msg)
			return Promise.reject(response)
		case 401:
		case 403:
			if (timer) return
			timer = setTimeout(() => {
				timer = null
				Message.error('登录失效，请重新登录')
			}, 500);
			store.dispatch('user/logout')
			router.replace(`/login?redirect=${router.currentRoute.path}`)
			return Promise.reject(response)
		default:
			Message.error(data.msg)
			return Promise.reject(response)
	}
}, error => {
	console.log(error,99)
	let code = error.response.status
	//判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
	// if (error.config.headers.showLoading !== false) {
	// 	hideLoading();
	// }
	switch (code) {
		case 401:
			if (timer) return
			timer = setTimeout(() => {
				timer = null
				Message.error('未登录')
			}, 500);
			store.dispatch('user/logout')
			router.replace(`/login?redirect=${router.currentRoute.path}`)
			return Promise.reject(error)
		case 403:
			if (timer) return
			timer = setTimeout(() => {
				timer = null
				Message.error('登录失效，请重新登录')
			}, 500);
			store.dispatch('user/logout')
			router.replace(`/login?redirect=${router.currentRoute.path}`)
			return Promise.reject(error)
		case 404:
			Message.error('网络请求不存在')
			break;
		case 409:
			Message.error(error.response.data.message)
			break
		case 500:
			console.log(error.response)
			Message.error('服务端出错了')
			break
		default:
			Message.error('未知错误')
			break
	}
	return Promise.reject(error)
}
)
export default instance
