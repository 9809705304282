import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: () => import('@/views/knowledge.vue')
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner.vue')
  },
  {
    path: '/pdfShow',
    name: 'pdfShow',
    component: () => import('@/views/pdfShow.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news.vue')
  },
  {
    path: '/knowledgeDetail',
    name: 'knowledgeDetail',
    component: () => import('@/views/knowledgeDetail.vue')
  },
  {
    path: '/surfaceDetail',
    name: 'surfaceDetail',
    component: () => import('@/views/surfaceDetail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue')
  },
  {
    path: '/partyBuilding',
    name: 'partyBuilding',
    component: () => import('@/views/partyBuilding.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my.vue')
  },
  {
    path: '/surface',
    name: 'surface',
    component: () => import('@/views/surface.vue')
  },
  {
    path: '/doctorDetail',
    name: 'doctorDetail',
    component: () => import('@/views/doctorDetail.vue')
  },
  {
    path: '/consultingService',
    name: 'consultingService',
    component: () => import('@/views/consultingService.vue')
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: () => import('@/views/curriculum.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
